import React, { useEffect, useState } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    Paper,
    LinearProgress,
    CircularProgress,
    Grid2,
    Card,
    CardContent,
    CardActions,
    TextField,
    Chip,
    Alert,
    AlertTitle,
    Collapse,
    IconButton,
} from '@mui/material';
import api from '../services/api';
import { MCQuestionOut, TopicOut, UserProgressOut } from '../types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Practice: React.FC = () => {
    const [topics, setTopics] = useState<TopicOut[]>([]);
    const [selectedTopics, setSelectedTopics] = useState<number[]>([]);
    const [questionsPerTopic, setQuestionsPerTopic] = useState<number>(3);
    const [questions, setQuestions] = useState<MCQuestionOut[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [showResult, setShowResult] = useState<boolean>(false);
    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [generating, setGenerating] = useState<boolean>(false);
    const [showQuestions, setShowQuestions] = useState<boolean>(false);
    const [hasIncompleteQuestions, setHasIncompleteQuestions] = useState<boolean>(false);
    const [incompleteQuestions, setIncompleteQuestions] = useState<MCQuestionOut[]>([]);
    const [showContext, setShowContext] = useState<boolean>(false);

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await api.get('/topics');
                setTopics(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch topics:', error);
                setLoading(false);
            }
        };
        fetchTopics();
    }, []);

    const fetchUserProgress = async () => {
        try {
            const [questionsRes, progressRes] = await Promise.all([
                api.get('/questions'),
                api.get('/progress')
            ]);

            const questions: MCQuestionOut[] = questionsRes.data;
            const progress: UserProgressOut[] = progressRes.data;

            const incomplete = questions.filter(question =>
                !progress.some(p => p.question_id === question.id)
            );

            setIncompleteQuestions(incomplete);
            setHasIncompleteQuestions(incomplete.length > 0);
        } catch (error) {
            console.error('Failed to fetch user progress:', error);
        }
    };

    useEffect(() => {
        fetchUserProgress();
    }, []);

    const handleTopicToggle = (topicId: number) => {
        setSelectedTopics(prev =>
            prev.includes(topicId)
                ? prev.filter(id => id !== topicId)
                : [...prev, topicId]
        );
    };

    const handleGenerateQuestions = async () => {
        if (selectedTopics.length === 0) {
            alert('Please select at least one topic');
            return;
        }

        setGenerating(true);
        try {
            const response = await api.post('/questions', {
                topic_ids: selectedTopics,
                num_questions_per_topic: questionsPerTopic
            });
            setQuestions(response.data);
            setShowQuestions(true);
            setCurrentIndex(0);
            setProgress(0);
            setSelectedOption('');
            setShowResult(false);
        } catch (error) {
            console.error('Failed to generate questions:', error);
            alert('Failed to generate questions. Please try again.');
        } finally {
            setGenerating(false);
        }
    };

    const handleSubmit = async () => {
        if (!selectedOption) {
            alert('Please select an option');
            return;
        }
        const currentQuestion = questions[currentIndex];
        const correct = selectedOption === currentQuestion.correct_option_id;
        setIsCorrect(correct);
        setShowResult(true);
        setProgress(((currentIndex + 1) / questions.length) * 100);

        try {
            await api.post('/progress', {
                question_id: currentQuestion.id,
                selected_option_id: selectedOption,
                is_correct: correct,
            });
        } catch (error) {
            console.error('Failed to record progress');
        }
    };

    const handleNext = () => {
        setShowResult(false);
        setSelectedOption('');
        if (currentIndex < questions.length - 1) {
            setCurrentIndex(currentIndex + 1);
        } else {
            setShowQuestions(false);
            setSelectedTopics([]);
            setQuestionsPerTopic(3);
            fetchUserProgress();
        }
    };

    if (loading) {
        return (
            <Container>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (!showQuestions) {
        return (
            <Container maxWidth="lg">
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Generate Practice Questions
                    </Typography>

                    {hasIncompleteQuestions && (
                        <Alert
                            severity="warning"
                            sx={{ mb: 3 }}
                            action={
                                <Button
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setQuestions(incompleteQuestions);
                                        setShowQuestions(true);
                                        setCurrentIndex(0);
                                        setProgress(0);
                                        setSelectedOption('');
                                        setShowResult(false);
                                    }}
                                >
                                    Continue Questions
                                </Button>
                            }
                        >
                            <AlertTitle>Incomplete Questions Found</AlertTitle>
                            You have {incompleteQuestions.length} unfinished question{incompleteQuestions.length > 1 ? 's' : ''}.
                            Please complete these questions before generating new ones.
                        </Alert>
                    )}

                    <Paper sx={{ p: 3, mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Select Topics
                        </Typography>
                        <Grid2 container spacing={2} sx={{ mb: 4 }}>
                            {topics.map((topic) => (
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={topic.id}>
                                    <Card
                                        sx={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            cursor: 'pointer',
                                            border: selectedTopics.includes(topic.id)
                                                ? '2px solid #1976d2'
                                                : '1px solid rgba(0, 0, 0, 0.12)',
                                        }}
                                        onClick={() => handleTopicToggle(topic.id)}
                                    >
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom>
                                                {topic.name}
                                            </Typography>
                                            {topic.description && (
                                                <Typography variant="body2" color="text.secondary">
                                                    {topic.description}
                                                </Typography>
                                            )}
                                        </CardContent>
                                        <CardActions sx={{ mt: 'auto', p: 2 }}>
                                            <Chip
                                                label={selectedTopics.includes(topic.id) ? 'Selected' : 'Select'}
                                                color={selectedTopics.includes(topic.id) ? 'primary' : 'default'}
                                            />
                                        </CardActions>
                                    </Card>
                                </Grid2>
                            ))}
                        </Grid2>

                        <Grid2 container sx={{ mb: 3 }} component="div">
                            <Grid2 size={{ xs: 6, sm: 2.35 }} component="div">
                                <Typography variant="subtitle1" gutterBottom>
                                    Questions per Topic
                                </Typography>
                                <TextField
                                    type="number"
                                    value={questionsPerTopic}
                                    onChange={(e) => setQuestionsPerTopic(Math.max(1, parseInt(e.target.value) || 1))}
                                    InputProps={{ inputProps: { min: 1, max: 10 } }}
                                    sx={{ width: 200 }}
                                    disabled={true}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6, sm: 2.35 }} component="div">
                                <Typography variant="subtitle1" gutterBottom>
                                    Difficulty (Question Order)
                                </Typography>
                                <TextField
                                    type="text"
                                    value={3}
                                    onChange={(e) => {/* your onChange handler here */ }}
                                    sx={{ width: 200 }}
                                    disabled={true}
                                />
                            </Grid2>
                        </Grid2>


                        {selectedTopics.length > 0 && (
                            <Alert severity="info" sx={{ mb: 3 }}>
                                {`Selected ${selectedTopics.length} topic${selectedTopics.length > 1 ? 's' : ''} - 
                                Will generate ${selectedTopics.length * questionsPerTopic} questions in total`}
                            </Alert>
                        )}

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGenerateQuestions}
                            disabled={generating || selectedTopics.length === 0 || hasIncompleteQuestions}
                            sx={{ minWidth: 200 }}
                        >
                            {generating ? (
                                <>
                                    <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                                    Generating...
                                </>
                            ) : hasIncompleteQuestions ? (
                                'Complete Existing Questions First'
                            ) : (
                                'Generate Questions'
                            )}
                        </Button>
                    </Paper>
                </Box>
            </Container>
        );
    }

    const currentQuestion = questions[currentIndex];

    return (
        <Container maxWidth="md">
            <Box sx={{ mt: 2, mb: 4 }}>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        height: 4,
                        borderRadius: 2,
                        backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                        Question {currentIndex + 1} of {questions.length}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                        {topics.find(t => t.id === currentQuestion.topic_id)?.name}
                        {currentQuestion.subtopic && ` • ${currentQuestion.subtopic}`}
                    </Typography>
                </Box>

                <Paper elevation={1} sx={{ p: 3, borderRadius: 1, backgroundColor: '#fcfcfc' }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 500, lineHeight: 1.3 }}>
                        {currentQuestion.question_text}
                    </Typography>

                    <RadioGroup
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                    >
                        {currentQuestion.options.map((option) => (
                            <FormControlLabel
                                key={option.option_id}
                                value={option.option_id}
                                control={
                                    <Radio
                                        size="small"
                                        sx={{
                                            '&.Mui-checked': {
                                                color: showResult
                                                    ? option.option_id === currentQuestion.correct_option_id
                                                        ? 'success.main'
                                                        : 'error.main'
                                                    : 'primary.main'
                                            }
                                        }}
                                    />
                                }
                                label={
                                    <Box sx={{ ml: 0.5, py: 0.5 }}>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: showResult
                                                    ? option.option_id === currentQuestion.correct_option_id
                                                        ? 'success.main'
                                                        : option.option_id === selectedOption
                                                            ? 'error.main'
                                                            : 'text.primary'
                                                    : 'text.primary',
                                                fontWeight: 400
                                            }}
                                        >
                                            {option.option_text}
                                        </Typography>
                                        {showResult &&
                                            (option.option_id === currentQuestion.correct_option_id ||
                                                option.option_id === selectedOption) && (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        mt: 0.5,
                                                        color: 'text.secondary',
                                                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                                        p: 1.5,
                                                        borderRadius: 1,
                                                        borderLeft: '3px solid',
                                                        borderColor: option.option_id === currentQuestion.correct_option_id
                                                            ? 'success.main'
                                                            : 'error.main'
                                                    }}
                                                >
                                                    {option.justification}
                                                </Typography>
                                            )}
                                    </Box>
                                }
                                disabled={showResult}
                                sx={{
                                    mb: 1,
                                    ml: 0,
                                    width: '100%',
                                    '&:hover': {
                                        backgroundColor: !showResult ? 'rgba(0, 0, 0, 0.02)' : 'transparent'
                                    }
                                }}
                            />
                        ))}
                    </RadioGroup>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {!showResult ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                size="medium"
                                disabled={!selectedOption}
                                sx={{ minWidth: 100 }}
                            >
                                Submit
                            </Button>
                        ) : (
                            <>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: isCorrect ? 'success.main' : 'error.main',
                                        fontWeight: 500
                                    }}
                                >
                                    {isCorrect ? 'Correct!' : 'Incorrect'}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    size="medium"
                                    sx={{ minWidth: 100 }}
                                >
                                    {currentIndex < questions.length - 1 ? 'Next' : 'Finish'}
                                </Button>
                            </>
                        )}
                    </Box>

                    <Box sx={{ mt: 4, borderTop: 1, borderColor: 'divider', pt: 2 }}>
                        <Button
                            onClick={() => setShowContext(!showContext)}
                            endIcon={showContext ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            sx={{ color: 'text.secondary' }}
                            size="small"
                        >
                            Medical Context
                        </Button>

                        <Collapse in={showContext}>
                            <Typography
                                variant="body2"
                                sx={{
                                    mt: 1,
                                    p: 2,
                                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                    borderRadius: 1,
                                    borderLeft: '3px solid',
                                    borderColor: 'primary.main'
                                }}
                            >
                                {currentQuestion.medical_context}
                            </Typography>
                        </Collapse>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
};

export default Practice;
