import React, { useContext, useEffect, useState } from 'react';
import {
    Container,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Modal,
    Card,
    CardContent,
    Collapse,
    Button,
    Grid,
    Chip,
    Divider,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import api from '../services/api';
import { AuthContext } from '../components/AuthContext';
import { ResourceQuery, MCQuestionOut, UserProgressOut, TopicOut } from '../types';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import ReactMarkdown from 'react-markdown';

interface ModalState {
    open: boolean;
    query: ResourceQuery | null;
}

interface MCQuestionWithProgress extends MCQuestionOut {
    userProgress?: UserProgressOut;
}

interface MCModalState {
    open: boolean;
    question: MCQuestionWithProgress | null;
}

interface QuizGroup {
    date: string;
    questions: MCQuestionWithProgress[];
}

const Dashboard: React.FC = () => {
    const { user } = useContext(AuthContext);
    const [queries, setQueries] = useState<ResourceQuery[]>([]);
    const [mcQuestions, setMCQuestions] = useState<MCQuestionWithProgress[]>([]);
    const [userProgress, setUserProgress] = useState<UserProgressOut[]>([]);
    const [modalState, setModalState] = useState<ModalState>({
        open: false,
        query: null
    });
    const [contextExpanded, setContextExpanded] = useState(false);
    const [mcModalState, setMcModalState] = useState<MCModalState>({
        open: false,
        question: null
    });
    const [topics, setTopics] = useState<TopicOut[]>([]);
    const [expandedQuizzes, setExpandedQuizzes] = useState<Record<string, boolean>>({});
    const [page, setPage] = useState(1);
    const [quizzesPerPage] = useState(5);
    const [qaPage, setQAPage] = useState(1);
    const [queriesPerPage] = useState(10);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [queriesRes, questionsRes, progressRes] = await Promise.all([
                    api.get('/resource-queries'),
                    api.get('/questions'),
                    api.get('/progress')
                ]);

                setQueries(queriesRes.data);

                // Combine questions with their progress
                const questions: MCQuestionOut[] = questionsRes.data;
                const progress: UserProgressOut[] = progressRes.data;

                const questionsWithProgress = questions.map((question) => ({
                    ...question,
                    userProgress: progress.find((p) => p.question_id === question.id)
                }));

                setMCQuestions(questionsWithProgress);
                setUserProgress(progress);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await api.get('/topics');
                setTopics(response.data);
            } catch (error) {
                console.error('Error fetching topics:', error);
            }
        };
        fetchTopics();
    }, []);

    const handleOpenModal = (query: ResourceQuery) => {
        setModalState({
            open: true,
            query
        });
    };

    const handleCloseModal = () => {
        setModalState({
            open: false,
            query: null
        });
        setContextExpanded(false);
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // Use 24-hour format; set to true for 12-hour format
        };
        return date.toLocaleString('en-US', options);
    };

    const calculateStats = () => {
        const total = userProgress.length;
        const correct = userProgress.filter(p => p.is_correct).length;
        return {
            total,
            correct,
            percentage: total > 0 ? Math.round((correct / total) * 100) : 0
        };
    };

    const stats = calculateStats();

    const handleOpenMCModal = (question: MCQuestionWithProgress) => {
        setMcModalState({
            open: true,
            question
        });
    };

    const handleCloseMCModal = () => {
        setMcModalState({
            open: false,
            question: null
        });
    };

    // Add this helper function to group questions by topic
    const groupQuestionsByTopic = (questions: MCQuestionWithProgress[]) => {
        return questions.reduce((groups, question) => {
            const topic = topics.find(t => t.id === question.topic_id);
            const topicName = topic ? topic.name : 'Uncategorized';

            if (!groups[topicName]) {
                groups[topicName] = [];
            }
            groups[topicName].push(question);
            return groups;
        }, {} as Record<string, MCQuestionWithProgress[]>);
    };

    // Add this helper function to calculate topic stats
    const calculateTopicStats = (questions: MCQuestionWithProgress[]) => {
        const total = questions.length;
        const correct = questions.filter(q => q.userProgress?.is_correct).length;
        return {
            total,
            correct,
            percentage: total > 0 ? Math.round((correct / total) * 100) : 0
        };
    };

    // Update the grouping function to use full timestamp
    const groupQuestionsByQuiz = (questions: MCQuestionWithProgress[]): QuizGroup[] => {
        const groupedByTimestamp = questions.reduce((groups, question) => {
            const timestamp = question.created_at; // Use full timestamp
            if (!groups[timestamp]) {
                groups[timestamp] = [];
            }
            groups[timestamp].push(question);
            return groups;
        }, {} as Record<string, MCQuestionWithProgress[]>);

        return Object.entries(groupedByTimestamp)
            .map(([timestamp, questions]) => ({
                date: timestamp,
                questions
            }))
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()); // Sort by timestamp descending
    };

    const quizGroups = groupQuestionsByQuiz(mcQuestions);

    const handleQuizToggle = (quizDate: string) => {
        setExpandedQuizzes(prev => ({
            ...prev,
            [quizDate]: !prev[quizDate]
        }));
    };

    // Add helper function to calculate quiz statistics
    const calculateQuizStats = (questions: MCQuestionWithProgress[]) => {
        const total = questions.length;
        const correct = questions.filter(q => q.userProgress?.is_correct).length;
        return {
            total,
            correct,
            percentage: total > 0 ? Math.round((correct / total) * 100) : 0
        };
    };

    // Calculate pagination for quizzes
    const pageCount = Math.ceil(quizGroups.length / quizzesPerPage);
    const currentQuizzes = quizGroups.slice(
        (page - 1) * quizzesPerPage,
        page * quizzesPerPage
    );

    // Calculate pagination for Q&A
    const qaPageCount = Math.ceil(queries.length / queriesPerPage);
    const currentQueries = queries.slice(
        (qaPage - 1) * queriesPerPage,
        qaPage * queriesPerPage
    );

    return (
        <Container maxWidth="lg">
            <Box sx={{ mt: 4, mb: 6 }}>
                <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                    Quiz History
                </Typography>

                {currentQuizzes.map((quizGroup) => {
                    const quizStats = calculateQuizStats(quizGroup.questions);
                    const isExpanded = expandedQuizzes[quizGroup.date] || false;

                    return (
                        <Paper
                            key={quizGroup.date}
                            elevation={1}
                            sx={{
                                mb: 2,
                                overflow: 'hidden',
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    boxShadow: 2
                                }
                            }}
                        >
                            <Box
                                onClick={() => handleQuizToggle(quizGroup.date)}
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    cursor: 'pointer',
                                    bgcolor: 'background.paper',
                                    borderBottom: isExpanded ? 1 : 0,
                                    borderColor: 'divider'
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    <Typography variant="h6">
                                        {formatDate(quizGroup.date)}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                    <Chip
                                        label={`${quizStats.correct}/${quizStats.total} correct`}
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                    />
                                    <Chip
                                        label={`${quizStats.percentage}%`}
                                        color={quizStats.percentage >= 70 ? "success" : "warning"}
                                        size="small"
                                    />
                                </Box>
                            </Box>

                            <Collapse in={isExpanded}>
                                <Box sx={{ p: 3 }}>
                                    {Object.entries(groupQuestionsByTopic(quizGroup.questions)).map(([topic, topicQuestions]) => {
                                        const topicStats = calculateTopicStats(topicQuestions);
                                        return (
                                            <Box key={`${quizGroup.date}-${topic}`} sx={{ mb: 4 }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    mb: 2,
                                                    pb: 1,
                                                    borderBottom: 1,
                                                    borderColor: 'divider'
                                                }}>
                                                    <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 500 }}>
                                                        {topic}
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        {topicStats.correct}/{topicStats.total} ({topicStats.percentage}% correct)
                                                    </Typography>
                                                </Box>
                                                <TableContainer>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Question</TableCell>
                                                                <TableCell>Your Answer</TableCell>
                                                                <TableCell>Result</TableCell>
                                                                <TableCell align="center">Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {topicQuestions.map((question) => (
                                                                <TableRow key={question.id}>
                                                                    <TableCell>
                                                                        {question.question_text.substring(0, 100)}
                                                                        {question.question_text.length > 100 ? '...' : ''}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {question.userProgress?.selected_option_id || 'N/A'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Chip
                                                                            label={question.userProgress?.is_correct ? 'Correct' : 'Incorrect'}
                                                                            color={question.userProgress?.is_correct ? 'success' : 'error'}
                                                                            size="small"
                                                                            variant="outlined"
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <IconButton
                                                                            onClick={() => handleOpenMCModal(question)}
                                                                            color="primary"
                                                                            size="small"
                                                                        >
                                                                            <VisibilityIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Collapse>
                        </Paper>
                    );
                })}

                {pageCount > 1 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Pagination
                            count={pageCount}
                            page={page}
                            onChange={(e, value) => setPage(value)}
                            color="primary"
                        />
                    </Box>
                )}
            </Box>

            <Box sx={{ mt: 6, mb: 4 }}>
                <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                    Your Previous Questions & Answers
                </Typography>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Question</TableCell>
                                <TableCell>Answer Preview</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentQueries.map((query) => (
                                <TableRow key={query.id}>
                                    <TableCell>
                                        {formatDate(query.created_at)}
                                    </TableCell>
                                    <TableCell>
                                        {query.question_text && query.question_text.substring(0, 100)}
                                        {query.question_text && query.question_text.length > 100 ? '...' : ''}
                                    </TableCell>
                                    <TableCell>
                                        {query.answer_text && query.answer_text.substring(0, 100)}
                                        {query.answer_text && query.answer_text.length > 100 ? '...' : ''}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={() => handleOpenModal(query)}
                                            color="primary"
                                            size="small"
                                        >
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {qaPageCount > 1 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Pagination
                            count={qaPageCount}
                            page={qaPage}
                            onChange={(e, value) => setQAPage(value)}
                            color="primary"
                        />
                    </Box>
                )}
            </Box>

            {/* Modals */}
            <Modal
                open={mcModalState.open}
                onClose={handleCloseMCModal}
                aria-labelledby="mc-question-detail-modal"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                BackdropProps={{
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    }
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxHeight: '80vh',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 4,
                    overflowY: 'auto'
                }}>
                    {mcModalState.question && (
                        <>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 3,
                                borderBottom: 1,
                                borderColor: 'divider',
                                pb: 2
                            }}>
                                <Typography variant="h6">
                                    Multiple Choice Question Details
                                </Typography>
                                <IconButton onClick={handleCloseMCModal}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h6" color="primary" gutterBottom>
                                    Question
                                </Typography>
                                <Paper elevation={0} sx={{ p: 3, bgcolor: 'grey.50', borderRadius: 2 }}>
                                    <Typography variant="body1">
                                        {mcModalState.question.question_text}
                                    </Typography>
                                </Paper>
                            </Box>

                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h6" color="primary" gutterBottom>
                                    Options
                                </Typography>
                                {mcModalState.question.options.map((option) => (
                                    <Paper
                                        key={option.option_id}
                                        elevation={0}
                                        sx={{
                                            p: 2,
                                            mb: 2,
                                            bgcolor: 'grey.50',
                                            borderRadius: 2,
                                            border: '1px solid',
                                            borderColor:
                                                option.option_id === mcModalState.question?.userProgress?.selected_option_id
                                                    ? mcModalState.question?.userProgress?.is_correct
                                                        ? 'success.main'
                                                        : 'error.main'
                                                    : option.option_id === mcModalState.question?.correct_option_id
                                                        ? 'success.main'
                                                        : 'grey.200'
                                        }}
                                    >
                                        <Typography variant="subtitle1" gutterBottom>
                                            Option {option.option_id}
                                            {option.option_id === mcModalState?.question?.userProgress?.selected_option_id && (
                                                <Chip
                                                    label="Your Answer"
                                                    size="small"
                                                    color={mcModalState.question.userProgress.is_correct ? "success" : "error"}
                                                    sx={{ ml: 1 }}
                                                />
                                            )}
                                            {option.option_id === mcModalState?.question?.correct_option_id && (
                                                <Chip
                                                    label="Correct Answer"
                                                    size="small"
                                                    color="success"
                                                    sx={{ ml: 1 }}
                                                />
                                            )}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {option.option_text}
                                        </Typography>
                                        {option.justification && (
                                            <Box sx={{ mt: 1 }}>
                                                <Typography variant="subtitle2" color="text.secondary">
                                                    Justification:
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {option.justification}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Paper>
                                ))}
                            </Box>
                        </>
                    )}
                </Box>
            </Modal>

            <Modal
                open={modalState.open}
                onClose={handleCloseModal}
                aria-labelledby="query-detail-modal"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                BackdropProps={{
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        backdropFilter: 'blur(4px)'
                    }
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxHeight: '85vh',
                    bgcolor: 'background.paper',
                    boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                    borderRadius: 3,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {modalState.query && (
                        <>
                            {/* Header */}
                            <Box sx={{
                                p: 2.5,
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                bgcolor: 'background.paper',
                                position: 'sticky',
                                top: 0,
                                zIndex: 1
                            }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <CalendarTodayIcon sx={{ fontSize: 'small', color: 'primary.main' }} />
                                    <Typography variant="subtitle2" color="text.secondary">
                                        {formatDate(modalState.query.created_at)}
                                    </Typography>
                                </Box>
                                <IconButton
                                    onClick={handleCloseModal}
                                    size="small"
                                    sx={{
                                        color: 'text.secondary',
                                        '&:hover': {
                                            bgcolor: 'grey.100',
                                            color: 'primary.main'
                                        }
                                    }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Box>

                            {/* Content */}
                            <Box sx={{
                                p: 3,
                                overflowY: 'auto',
                                flex: 1,
                                '&::-webkit-scrollbar': {
                                    width: '8px'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0,0,0,.1)',
                                    borderRadius: '4px'
                                }
                            }}>
                                {/* Question Section */}
                                <Box sx={{ mb: 4 }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: 'primary.main',
                                            fontWeight: 600,
                                            mb: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1
                                        }}
                                    >
                                        Question
                                    </Typography>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            p: 3,
                                            bgcolor: 'grey.50',
                                            borderRadius: 2,
                                            border: '1px solid',
                                            borderColor: 'divider'
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                                            {modalState.query.question_text}
                                        </Typography>
                                    </Paper>
                                </Box>

                                {/* Answer Section */}
                                <Box sx={{ mb: 4 }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: 'secondary.main',
                                            fontWeight: 600,
                                            mb: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1
                                        }}
                                    >
                                        Answer
                                    </Typography>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            p: 3,
                                            bgcolor: 'grey.50',
                                            borderRadius: 2,
                                            border: '1px solid',
                                            borderColor: 'divider'
                                        }}
                                    >
                                        <ReactMarkdown
                                            components={{
                                                p: ({ children }) => (
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            fontFamily: 'monospace',
                                                            whiteSpace: 'pre-wrap',
                                                            mb: 2,
                                                            lineHeight: 1.7
                                                        }}
                                                    >
                                                        {children}
                                                    </Typography>
                                                ),
                                                code: ({ children }) => (
                                                    <Typography
                                                        component="code"
                                                        sx={{
                                                            backgroundColor: 'grey.100',
                                                            p: 0.5,
                                                            borderRadius: 1,
                                                            fontFamily: 'monospace'
                                                        }}
                                                    >
                                                        {children}
                                                    </Typography>
                                                )
                                            }}
                                        >
                                            {modalState.query.answer_text}
                                        </ReactMarkdown>
                                    </Paper>
                                </Box>

                                {/* Context Section */}
                                <Box>
                                    <Button
                                        onClick={() => setContextExpanded(!contextExpanded)}
                                        startIcon={contextExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        variant="text"
                                        sx={{
                                            color: 'text.secondary',
                                            '&:hover': {
                                                backgroundColor: 'grey.50'
                                            }
                                        }}
                                    >
                                        {contextExpanded ? 'Hide Medical Context' : 'Show Medical Context'}
                                    </Button>
                                    <Collapse in={contextExpanded}>
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                mt: 2,
                                                p: 3,
                                                bgcolor: 'grey.50',
                                                borderRadius: 2,
                                                border: '1px solid',
                                                borderColor: 'divider'
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color: 'text.secondary',
                                                    lineHeight: 1.7
                                                }}
                                            >
                                                {modalState.query.context}
                                            </Typography>
                                        </Paper>
                                    </Collapse>
                                </Box>
                            </Box>

                            {/* Footer */}
                            <Box sx={{
                                p: 2,
                                borderTop: '1px solid',
                                borderColor: 'divider',
                                bgcolor: 'grey.50',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: 1
                            }}>
                                <Chip
                                    label={`Response Style: ${modalState.query.response_style}`}
                                    size="small"
                                    variant="outlined"
                                    sx={{ fontSize: '0.75rem' }}
                                />
                                <Chip
                                    label={`Question Style: ${modalState.query.question_style}`}
                                    size="small"
                                    variant="outlined"
                                    sx={{ fontSize: '0.75rem' }}
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </Modal>
        </Container>
    );
};

export default Dashboard;
